import { styled } from '@creditas/stylitas'
import { media } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  max-width: 1366px;
  margin-right: auto;
  margin-left: auto;
  padding: 72px 72px 0px;

  ${media.up('sm')} {
    padding: 32px 10px 0;
  }
`

export { Wrapper }
