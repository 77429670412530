import React from 'react'
import { string, bool, arrayOf, shape } from 'prop-types'
import ConditionalRender from 'components/ConditionalRender'
import { SITE_SECTIONS } from 'src/constants'
import Link from '../Link'

import { Label, SectionItems } from './styles'
const propTypes = {
  label: string,
  showLabel: bool,
  categories: arrayOf(
    shape({
      slug: string,
      title: string,
    }),
  ),
}

const MenuSection = ({ label, showLabel, categories }) => (
  <>
    <ConditionalRender condition={showLabel}>
      <Label component="span">{label}</Label>
    </ConditionalRender>

    <div>
      {categories?.map(
        (category, index) =>
          category.isVisibleTo?.includes(SITE_SECTIONS.SUBMENU) && (
            <SectionItems key={index}>
              <Link
                type={showLabel ? 'SectionLink' : 'SubcategoryLink'}
                to={`/${category.slug}`}
              >
                {category.title}
              </Link>
            </SectionItems>
          ),
      )}
    </div>
  </>
)

MenuSection.propTypes = propTypes

export default MenuSection
