import { getColorToken } from 'src/utils/getColorToken'
import { Link as GatsbyLink } from 'gatsby'
import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

export const Link = styled(GatsbyLink)`
  padding: 0 !important;
  margin: 0 !important;
  margin: ${selectTheme('spacingX.3xl')} 0 !important;

  border-bottom: 1px solid transparent;

  ${media.up('md')} {
    &:hover {
      border-bottom: 1px solid ${getColorToken('primary.60')};
    }
  }
`

export const SectionLink = styled(Link)`
  display: block !important;
  color: ${getColorToken('neutral.100')} !important;

  ${getTypography('bodyMdLight')}
  font-size: ${selectTheme('fontSizes.md')} !important;
  font-weight: ${selectTheme('fontWeight.xs')} !important;
`

export const SubcategoryLink = styled(Link)`
  display: block;

  ${getTypography('bodyMdRegular')}

  font-weight: ${selectTheme('fontWeight.sm')} !important;
  font-size: ${selectTheme('fontSizes.md')} !important;
`
