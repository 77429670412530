const getScrollTargetOffset = element => {
  const elementOffsetTop = element.offsetTop
  const isMobile = window.innerWidth < 960
  const headerHeight = isMobile ? 48 : 80

  return elementOffsetTop - headerHeight
}

const isValidDOMSelector = selector => {
  let element

  try {
    element = document.querySelector(selector)
  } catch {
    element = null
  }

  return element
}

const scrollTo = elementID => {
  const element = isValidDOMSelector(elementID)

  if (element) {
    window.scrollTo(0, getScrollTargetOffset(element))
    return
  }

  if (elementID === '#') {
    window.scrollTo(0, 0)
  }
}

export { scrollTo }
