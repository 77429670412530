import { styled, css, mq } from '@creditas/stylitas'
import { styled as _styled } from '@creditas-ui/system'
import { fillMediaBase } from 'ui/utils/fillMediaBase'
import { Container as CreditasContainer } from '@creditas/layout'
import Img from 'gatsby-image'
import { getColorToken } from 'src/utils/getColorToken'
import { Typography } from 'components/Typography'

const ImageElement = styled(Img)``

const Wrapper = styled.div(
  () => css`
    background-color: ${getColorToken('neutral.15')};
  `,
)

const Container = styled(CreditasContainer)`
  display: flex;
  padding: 16px;

  ${mq({
    alignItems: fillMediaBase('flex-start', 'center'),
    justifyContent: fillMediaBase('space-evenly', 'space-between'),
    flexDirection: fillMediaBase('column-reverse', 'row'),
  })}
`

const Text = _styled(Typography)`
  color: ${getColorToken('neutral.90')};
`

export { ImageElement, Wrapper, Container, Text }
