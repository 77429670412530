import { bool, node } from 'prop-types'

const propTypes = {
  condition: bool.isRequired,
  children: node.isRequired,
}

const ConditionalRender = ({ condition, children }) => {
  if (!condition) return null
  return children
}

ConditionalRender.propTypes = propTypes

export default ConditionalRender
