import { Typography } from '@creditas-ui/typography'

import { styled } from '@creditas-ui/system'
import { getTypography } from '@creditas-ui/utilities'

export const Label = styled(Typography)`
  display: block;
  padding: 16px 0 !important;
  ${getTypography('bodyMdRegular')}
`

export const SectionItems = styled.div`
  width: 100%;
`
