import { Container as CreditasContainer } from '@creditas/layout'
import { styled } from '@creditas-ui/system'
import { getTypography, selectTheme, media } from '@creditas-ui/utilities'
import { Typography } from 'components/Typography'

const Wrapper = styled.section`
  padding: 48px 0 56px;
  background-color: ${selectTheme('colors.neutral.90')};
`

const Container = styled(CreditasContainer)``

const Title = styled(Typography)`
  margin-bottom: 8px;
  color: ${selectTheme('colors.neutral.0')};
  ${getTypography('headingLgLight')}
`

const Description = styled(Typography)`
  color: ${selectTheme('colors.neutral.0')};
  ${getTypography('bodyMdRegular')}

  a {
    color: ${selectTheme('colors.primary.60')};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${media.up('5xl')} {
    ${getTypography('bodyXlLight')}
  }
`

export { Wrapper, Container, Title, Description }
