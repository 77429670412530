import React from 'react'
import { Helmet } from 'react-helmet'
import { string } from 'prop-types'

const propTypes = {
  title: string,
  description: string,
  account: string,
  url: string,
  image: string,
}

const Twitter = ({ title, description, account, url, image }) => (
  <Helmet>
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:url" content={url} />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:creator" content={account} />
    <meta name="twitter:site" content={account} />
    <meta name="twitter:image" content={image} />
  </Helmet>
)

Twitter.propTypes = propTypes

export { Twitter }
