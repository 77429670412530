import { getColorToken } from 'src/utils/getColorToken'
import React from 'react'

const PlayStore = ({ ...others }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.74645 16C1.81253 15.978 1.87862 15.945 1.95572 15.901L11.6155 10.6227L9.32445 8.3354L1.74645 16ZM12.034 10.3918L9.64387 8.01649L12.034 5.60825L15.3164 7.40069C16.1204 7.84055 15.7129 8.39038 15.3164 8.61031L12.034 10.3918ZM11.6045 5.36632L1.95572 0.109966C1.85659 0.0549828 1.73543 0.0219931 1.61427 0L9.31343 7.6756L11.6045 5.36632ZM1.05253 0.0989692L8.99401 8.01649L1.08558 16C0.85427 15.857 0.733111 15.5381 0.733111 15.1313C0.733111 14.3505 0.667024 1.90241 0.667024 0.967698C0.656009 0.505842 0.821227 0.230928 1.05253 0.0989692Z"
      fill={getColorToken('neutral.90')}
    />
  </svg>
)

export { PlayStore }
