import { mq, styled } from '@creditas/stylitas'
import {
  ButtonPrimaryTextOnly as CreditasButton,
  ButtonSecondary as CreditasActionLink,
} from '@creditas-ui/button'
import { Link } from 'gatsby'
import { fillMediaBase } from 'ui/utils/fillMediaBase'

const InternalLink = styled(Link)`
  display: flex;
  align-items: center;

  ${mq({
    maxWidth: fillMediaBase('100%', '288px'),
  })}

  text-align: center;
  text-decoration: none;
`

const ActionLink = styled(CreditasActionLink)`
  padding: 0;
`

const DefaultButton = styled(CreditasButton)``

export { InternalLink, ActionLink, DefaultButton }
