import { useStaticQuery, graphql } from 'gatsby'
import { SITE_SECTIONS } from 'src/constants'
import { Wrapper, ItemLink, Title } from './style'

const Menu = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulCategory {
        edges {
          node {
            id
            title
            slug
            isVisibleTo
          }
        }
      }
    }
  `)

  const {
    allContentfulCategory: { edges: categories },
  } = data

  return (
    <Wrapper>
      <ItemLink to="/">
        <Title>Home</Title>
      </ItemLink>
      {categories?.map(({ node: category }) =>
        category.title &&
        category.isVisibleTo?.includes(SITE_SECTIONS.FOOTER) ? (
          <ItemLink to={`/${category.slug}`} key={`menu-${category.slug}`}>
            <Title>{category.title}</Title>
          </ItemLink>
        ) : null,
      )}
    </Wrapper>
  )
}

export default Menu
