import { getColorToken } from 'src/utils/getColorToken'

export const typographyColors = {
  bHeadingh1Color: getColorToken('neutral.80'),
  bHeadingh1FallbackColor: getColorToken('neutral.80'),
  bHeadingh1SystemColor: getColorToken('neutral.80'),
  bHeadingh2Color: getColorToken('neutral.80'),
  bHeadingh2FallbackColor: getColorToken('neutral.80'),
  bHeadingh2SystemColor: getColorToken('neutral.80'),
  bHeadingh3Color: getColorToken('neutral.80'),
  bHeadingh3FallbackColor: getColorToken('neutral.80'),
  bHeadingh3SystemColor: getColorToken('neutral.80'),
  bHeadingh4Color: getColorToken('neutral.80'),
  bHeadingh4FallbackColor: getColorToken('neutral.80'),
  bHeadingh4SystemColor: getColorToken('neutral.80'),
  bHeadingh5Color: getColorToken('neutral.80'),
  bHeadingh5FallbackColor: getColorToken('neutral.80'),
  bHeadingh5SystemColor: getColorToken('neutral.80'),
  bHeadingh6Color: getColorToken('neutral.80'),
  bHeadingh6FallbackColor: getColorToken('neutral.80'),
  bHeadingh6SystemColor: getColorToken('neutral.80'),
  bLabalcaptionFallbackColor: getColorToken('secondary.60'),
  bLabelactionColor: getColorToken('secondary.60'),
  bLabelactionFallbackColor: getColorToken('secondary.60'),
  bLabelbuttonColor: getColorToken('secondary.60'),
  bLabelbuttonFallbackColor: getColorToken('secondary.60'),
  bLabelcaptionColor: getColorToken('secondary.60'),
  bLabelformColor: getColorToken('secondary.60'),
  bLabelformFallbackColor: getColorToken('secondary.60'),
  bTextparagraphColor: getColorToken('secondary.60'),
  bTextparagraphFallbackColor: getColorToken('secondary.60'),
  bTextparagraphSystemColor: getColorToken('secondary.60'),
  bTextsupportColor: getColorToken('secondary.60'),
  bTextsupportFallbackColor: getColorToken('secondary.60'),
  bTextsupportSystemColor: getColorToken('secondary.60'),
  bTextsupportformColor: getColorToken('secondary.60'),
  bTextsupportformFallbackColor: getColorToken('secondary.60'),
}

export const typographyFonts = {
  bHeadingh1FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh1FontWeight: '700',
  bHeadingh2FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh2FontWeight: '700',
  bHeadingh3FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh3FontWeight: '700',
  bHeadingh4FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh4FontWeight: '700',
  bHeadingh5FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh5FontWeight: '700',
  bHeadingh6FontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bHeadingh6FontWeight: '700',
  bTextparagraphFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextparagraphFontWeight: '400',
  bTextsupportFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextsupportFontWeight: '400',
  bTextsupportformFontFamily:
    '"Helvetica Now Display", "HelveticaNowDisplay-Medium"',
  bTextsupportformFontWeight: '400',
  bLabelformFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelformFontWeight: '700',
  bLabelbuttonFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelbuttonFontWeight: '700',
  bLabelactionFontFamily: '"Helvetica Now Display", "HelveticaNowDisplay-Bold"',
  bLabelactionFontWeight: '700',
}

export const colorsTokens = {
  bColorPrimaryLight: getColorToken('primary.40'),
  bColorPrimaryDefault: getColorToken('primary.60'),
  bColorPrimaryDark: getColorToken('primary.90'),
  bColorSecondaryLight: getColorToken('secondary.20'),
  bColorSecondaryDefault: getColorToken('secondary.40'),
  bColorSecondaryDark: getColorToken('secondary.60'),
  bColorNeutralWhite: getColorToken('neutral.0'),
  bColorNeutral10: getColorToken('neutral.10'),
  bColorNeutral15: getColorToken('neutral.15'),
  bColorNeutral20: getColorToken('neutral.15'),
  bColorNeutral40: getColorToken('neutral.40'),
  bColorNeutral60: getColorToken('neutral.60'),
  bColorNeutral80: getColorToken('neutral.60'),
  bColorNeutral90: getColorToken('neutral.90'),
  bColorNeutralBlack: getColorToken('neutral.90'),
  bColorSuccess: getColorToken('primary.60'),
  bColorWarning: getColorToken('warning.60'),
  bColorError: getColorToken('error.60'),
  bColorInfo: getColorToken('informational.60'),
}
