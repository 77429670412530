import Menu from './Menu'
import Copyright from './Copyright'
import SocialMedia from './SocialMedia'
import Logo from './Logo'
import { Wrapper, Container, List } from './style'

const FooterComponent = ({ ...others }) => (
  <Wrapper {...others}>
    <Container maxWidth="lg">
      <List>
        <Logo />
        <Menu />
        <SocialMedia />
      </List>
    </Container>
    <Copyright />
  </Wrapper>
)

export default FooterComponent
