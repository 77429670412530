import { ThemeProvider } from '@creditas-ui/system'
import { standard } from '@creditas-ui/themes'
import { ThemeProvider as StylitasProvider } from '@creditas/stylitas'
import tokens from '@creditas/tokens'
import newTokens from '@creditas/tokens/dist/new-version'
import { node } from 'prop-types'
import { colorsTokens } from 'ui/utils/typographyTokens'

const propTypes = {
  children: node.isRequired,
}

export const Theme = ({ children }) => {
  return (
    <StylitasProvider
      theme={{
        ...tokens,
        ...newTokens,
        ...colorsTokens,
      }}
    >
      <ThemeProvider theme={standard}>{children}</ThemeProvider>
    </StylitasProvider>
  )
}

Theme.propTypes = propTypes
