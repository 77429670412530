import React from 'react'
import { string, node } from 'prop-types'
import { SectionLink, SubcategoryLink } from './styles'

const propTypes = {
  type: string,
  children: node.isRequired,
}

const Link = ({ type, children, ...rest }) => {
  if (type === 'SectionLink')
    return <SectionLink {...rest}>{children}</SectionLink>
  return <SubcategoryLink {...rest}>{children}</SubcategoryLink>
}

Link.propTypes = propTypes
export default Link
