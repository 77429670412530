import { bool, oneOf, shape, string, func } from 'prop-types'
import { ArrowRight } from 'components/svgs'
import React, { useCallback } from 'react'
import { scrollTo } from 'ui/utils/scrollTo'
import { InternalLink, ActionLink, DefaultButton } from './ButtonCta.style'

const VARIANTS = {
  internalLink: InternalLink,
  actionLink: ActionLink,
  defaultButton: DefaultButton,
}

const propTypes = {
  title: string,
  label: string,
  variant: oneOf(['internalLink', 'actionLink', 'defaultButton']),
  icon: func,
  actionType: oneOf(['scrollTo', 'link']),
  actionHref: string,
  openInNewTab: bool,
  analyticEvent: shape({
    eventName: string,
    eventLabel: string,
    eventCategory: string,
    eventAction: string,
  }),
}

const defaultProps = {
  variant: 'internalLink',
  icon: ArrowRight,
  actionType: 'link',
  actionHref: '',
  openInNewTab: false,
}

const ButtonCta = ({
  variant,
  icon,
  label,
  actionHref,
  openInNewTab,
  analyticEvent,
  actionType,
  ...other
}) => {
  const Variant = VARIANTS[variant]

  const elementProps = {
    target: openInNewTab ? '_blank' : '_self',
    type: null,
    ...other,
  }

  const propsForInternalLink = {
    to: actionHref,
  }

  const propsForDefaultLink = {
    href: actionType === 'link' ? actionHref : null,
    icon,
  }

  const Icon = icon
  const hasInternalLink = variant === 'internalLink' && icon

  Object.assign(
    elementProps,
    variant === 'internalLink' ? propsForInternalLink : propsForDefaultLink,
  )

  const handleButtonOnClick = useCallback(() => {
    if (actionType !== 'link') {
      scrollTo(actionHref)
    }
  }, [actionType, actionHref])

  return (
    <Variant
      data-testid="button-cta"
      {...elementProps}
      onClick={handleButtonOnClick}
    >
      {label}
      {hasInternalLink && <Icon />}
    </Variant>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export { ButtonCta }
