import { styled } from '@creditas/stylitas'
import { Link } from 'gatsby'
import { media, getTypography } from '@creditas-ui/utilities'
import { styled as _styled } from '@creditas-ui/system'
import { getColorToken } from 'src/utils/getColorToken'
import { Typography } from 'components/Typography'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: -webkit-fill-available;

  ${media.up('5xl')} {
    width: auto;
    flex-direction: row;
    align-items: center;
  }
`

const ItemLink = styled(Link)`
  text-decoration: none;
  margin: 16px;
  flex: 2 0 auto;
  color: ${getColorToken('neutral.90')};

  ${media.up('5xl')} {
    margin: 16px 24px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Title = _styled(Typography)`
  color: ${getColorToken('neutral.90')};
  ${getTypography('bodyMdBold')}

  ${media.up('5xl')}{
    ${getTypography('bodySmBold')}
  }
`

export { Wrapper, ItemLink, Title }
