import { Global } from '@creditas/global'
import Contact from 'components/Contact'
import Footer from 'components/Footer'
import Header from 'components/Header'
import Metatags from 'components/Metatags'
import { node, object } from 'prop-types'
import { Theme } from 'src/components/ThemeProvider'
import { Main, customStyles } from './style'

const propTypes = {
  children: node.isRequired,
  metatags: object,
}

const Layout = ({ metatags, children }) => (
  <Theme>
    <Global custom={customStyles} />
    {metatags && <Metatags {...metatags} />}
    <Header />
    <Main>{children}</Main>
    <Contact
      title="Investor contact"
      description="For more informations, details, or questions, please reach out to our Investor Relations team at <a href='mailto: investor-relations@creditas.com'>investor-relations@creditas.com</a> or our Public Relations team at <a href='mailto: imprensa@creditas.com.br'>imprensa@creditas.com.br</a>."
    />
    <Footer />
  </Theme>
)

Layout.propTypes = propTypes
Layout.displayName = 'Layout'

export { Layout }
