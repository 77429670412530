import { styled } from '@creditas/stylitas'
import { Container as CreditasContainer } from '@creditas/layout'
import { media } from '@creditas-ui/utilities'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled.footer`
  background-color: ${getColorToken('neutral.10')};
`

const Container = styled(CreditasContainer)`
  padding: 24px 0 32px;

  ${media.up('5xl')} {
    padding: 64px 0;
  }
`

const List = styled.footer`
  background-color: ${getColorToken('neutral.10')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  ${media.up('5xl')} {
    flex-direction: row;
  }
`

export { Wrapper, Container, List }
