import { Facebook, Twitter, Instagram, Linkedin } from 'components/svgs'
import { Wrapper, SocialMediaIcon } from './style'

const IconList = [
  {
    id: 1,
    href: 'https://www.facebook.com/creditasbr',
    icon: Facebook,
  },
  {
    id: 2,
    href: 'https://twitter.com/Creditas_Br',
    icon: Twitter,
  },
  {
    id: 3,
    href: 'https://www.instagram.com/creditasbr',
    icon: Instagram,
  },
  {
    id: 4,
    href: 'https://www.linkedin.com/company/creditasbr/',
    icon: Linkedin,
  },
]

const SocialMedia = () => {
  return (
    <Wrapper>
      {IconList.map(({ href, id, icon: Icon }) => (
        <SocialMediaIcon href={href} target="_blank" key={id}>
          <Icon />
        </SocialMediaIcon>
      ))}
    </Wrapper>
  )
}

export default SocialMedia
