import CreditasLogo from 'images/logo-horizontal.svg'
import { Wrapper, LogoLink } from './style'

const Logo = () => {
  return (
    <Wrapper>
      <LogoLink href="https://creditas.com" target="_blank" rel="noreferrer">
        <img src={CreditasLogo} alt="Loading spinner" width={120} height={24} />
      </LogoLink>
    </Wrapper>
  )
}

export default Logo
