import { string } from 'prop-types'
import { Wrapper, Container, Title, Description } from './styles'

const propTypes = {
  title: string,
  description: string,
}

const Contact = ({ title, description }) => (
  <Wrapper>
    <Container maxWidth="lg">
      {title && <Title as="h2">{title}</Title>}
      {description && (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      )}
    </Container>
  </Wrapper>
)

Contact.propTypes = propTypes

export default Contact
