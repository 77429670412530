import React from 'react'
import { shape, arrayOf, string, bool } from 'prop-types'
import MenuSection from '../MenuSection'
import { Wrapper, ItemWrapper } from './styles'

const propTypes = {
  sections: arrayOf(
    shape({
      showLabel: bool,
      label: string,
      categories: arrayOf(shape({ slug: string, title: string })),
    }),
  ),
}

const Submenu = ({ sections }) => (
  <Wrapper>
    {sections?.map(
      section =>
        section?.categories?.length && (
          <ItemWrapper key={section.label}>
            <MenuSection {...section} />
          </ItemWrapper>
        ),
    )}
  </Wrapper>
)

Submenu.propTypes = propTypes

export default Submenu
