import React from 'react'

const ArrowRight = ({ ...others }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...others}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.0203 8.97978C18.825 8.78452 18.5085 8.78452 18.3132 8.97978C18.1179 9.17504 18.1179 9.49163 18.3132 9.68689L24.1263 15.5H6.66675C6.39061 15.5 6.16675 15.7239 6.16675 16C6.16675 16.2761 6.39061 16.5 6.66675 16.5H24.1263L18.3132 22.3131C18.1179 22.5084 18.1179 22.825 18.3132 23.0202C18.5085 23.2155 18.825 23.2155 19.0203 23.0202L25.687 16.3536C25.7349 16.3056 25.7711 16.2504 25.7955 16.1914C25.7959 16.1903 25.7964 16.1892 25.7968 16.1882C25.7986 16.1837 25.8003 16.1793 25.802 16.1749C25.8194 16.1281 25.8301 16.078 25.8328 16.0257C25.8329 16.0227 25.833 16.0197 25.8331 16.0166C25.8333 16.0111 25.8334 16.0056 25.8334 16C25.8334 15.9322 25.8199 15.8676 25.7955 15.8086C25.7944 15.8059 25.7932 15.8033 25.7921 15.8006C25.7678 15.7446 25.7327 15.6922 25.687 15.6464L19.0203 8.97978Z"
      fill="currentColor"
    />
  </svg>
)

export { ArrowRight }
