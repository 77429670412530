import { styled } from '@creditas/stylitas'
import { media } from '@creditas-ui/utilities'

const Wrapper = styled.div`
  width: -webkit-fill-available;
  margin: 0 16px;

  ${media.up('5xl')} {
    width: 120px;
    margin: 16px 64px 16px 0;
  }
`

const LogoLink = styled.a`
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
`

export { Wrapper, LogoLink }
