import { styled } from '@creditas/stylitas'

const Main = styled.main`
  padding-top: 56px;

  @media (min-width: 768px) {
    padding-top: 90px;
  }
`

const customStyles = `
  html {
    scroll-behavior: smooth;
  }
`

export { Main, customStyles }
