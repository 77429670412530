import React from 'react'
import { node } from 'prop-types'
import { Wrapper } from './styles'

const propTypes = {
  children: node,
}

const ContentWrapper = ({ children, ...others }) => (
  <Wrapper {...others}>{children}</Wrapper>
)

ContentWrapper.propTypes = propTypes

export default ContentWrapper
