import { styled } from '@creditas-ui/system'
import { getColorToken } from 'utils/getColorToken'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${getColorToken('neutral.0')};
  box-shadow: 0px 12px 12px 0px #00000014;
  box-shadow: 0px 0px 8px 0px #00000014;
  list-style: none;
  margin: 0;
  min-width: 288px;
  border-radius: 16px;
  z-index: 1;
`

export const ItemWrapper = styled.div`
  width: 100%;

  display: block;
  padding: 0 16px;

  :not(:last-child) {
    border-bottom: 1px solid ${getColorToken('neutral.15')};
  }
`
